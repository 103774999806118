<template>
    <div class="payConsole">
        <div class="content-left">
            <div class="recentVisit">
                <div class="title">
                    <div class="tab"></div>
                    <div class="visitText">最近访问</div>
                </div>
                <div style="display:flex;margin-top:10px">
                    <template v-for="item in dataList">
                        <div class="platform" style="margin-left:20px">
                            <div class="platformText" @click="jumpUrl(item.type)">{{ item.title.slice(0,2) }}平台</div>
                        </div>
                    </template>
                    <!--<div v-if="dataList[0] && dataList[0].show" class="platform" style="margin-left:20px">-->
                    <!--    <div class="platformText" @click="jumpUrl('pay')">支付平台</div>-->
                    <!--</div>-->
                    <!--<div v-if="dataList[1] && dataList[1].show" class="platform" style="margin-left:20px">-->
                    <!--    <div class="platformText" @click="jumpUrl('sms')">短信平台</div>-->
                    <!--</div>-->
                </div>
            </div>
            <div v-for="(item,index) in dataList" v-if="item.show" :key="index" class="data">
                <div class="title">
                    <div class="tab"></div>
                    <div class="visitText">{{ item.title }}</div>
                </div>
                <div style="display:flex;margin-top:25px">
                    <div class="left-block">
                        <div class="dateTitle">{{ item.leftText }}</div>
                        <div class="leftNum">{{ item.leftNum }}</div>
                    </div>
                    <div class="right-block">
                        <div class="dateTitle">{{ item.rightText }}</div>
                        <div class="rightNum">{{ item.rightNum }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-right">
            <div style="display:flex">
                <!--<img src="" class="userImg" alt="">-->
                <span class="userImg">{{ userInfo.ename ? userInfo.ename.slice(0,1) : '' }}</span>
                <div class="userName">{{ userInfo.ename }}</div>
            </div>
            <div style="display:flex;margin-top:17px">
                <!--<img src="" class="userId" alt="">-->
                账户ID
                <div class="userIdNum">{{ userInfo.id }}</div>
            </div>
            <!--<div class="title">-->
            <!--    <div class="tab" style="margin-left:0"></div>-->
            <!--    <div class="visitText">费用概览</div>-->
            <!--</div>-->
            <!--<div style="display:flex;margin-top:25px">-->
            <!--    <div>-->
            <!--        <div class="dateTitle">余额</div>-->
            <!--        <div class="rightNum">{{userInfo.budget}}</div>-->
            <!--    </div>-->
            <!--    <div style="margin-left:60px">-->
            <!--        <div class="dateTitle">待续费</div>-->
            <!--        <div class="rightNum">{{userInfo.renewed}}</div>-->
            <!--    </div>-->
            <!--</div>-->
        </div>
    </div>
</template>

<script>
  // import R3 from '@syman/burgeon-r3';
  // eslint-disable-next-line import/named
  import axios from 'axios';
  import { timeToDate } from '../../utils/methods';
    

  // const { network } = R3;
  export default {
    data() {
      return {
        SELF_ENV: process.env.SELF_ENV,
        dataList: [
          // {
          //   title: '支付数据',
          //   leftText: '交易额（7日）',
          //   leftNum: 8888.88,
          //   rightText: '交易量（7日）',
          //   rightNum: 55555.55,
          // },
          // {
          //   title: '短信数据',
          //   leftText: '发送量（7日）',
          //   leftNum: 2222.88,
          //   rightText: '剩余量（7日）',
          //   rightNum: 55598.55,
          // },
        ],
        userInfo: '',
      };
    },
    methods: {
      jumpUrl(type) {
        if (type === 'pay') {
          if (this.SELF_ENV === 'pro') {
            // window.open('https://console.pay.burgeonyun.com');
            window.open('https://pay-admin.burgeonyun.com');
          } else {
            window.open('http://console.pay.oneretail.cn');
          }
        } else if (type === 'sms') {
          if (this.SELF_ENV === 'pro') {
            window.open('https://console.sms.burgeonyun.com');
          } else {
            window.open('http://console.sms.oneretail.cn');
          }
        } else if (type === 'inv') {
          if (this.SELF_ENV === 'pro') {
            window.open('https://console.invoice.burgeonyun.com');
          } else {
            window.open('http://console.invoice.oneretail.cn');
          }
        }
      },
    },
    created() {
      // if (this.userInfo.payData) {
      //   this.dataList.push({
      //     title: '支付数据',
      //     type: 'pay',
      //     show: true,
      //     leftText: '交易额（7日）',
      //     leftNum: this.userInfo.payData.turnover,
      //     rightText: '交易量（7日）',
      //     rightNum: this.userInfo.payData.dealVolume,
      //   });
      // }
      // if (this.userInfo.smsData) {
      //   this.dataList.push({
      //     title: '短信数据',
      //     type: 'sms',
      //     show: true,
      //     leftText: '发送量（7日）',
      //     leftNum: this.userInfo.smsData.sendVolume,
      //     rightText: '剩余量（预估）',
      //     rightNum: this.userInfo.smsData.surplusVolume,
      //   });
      // }

      let nowTimestamp = Date.parse(new Date());
      let lastSevenTimestamp = nowTimestamp - 1000 * 60 * 60 * 24 * 6;
      let params = {
        startDate: timeToDate(lastSevenTimestamp, 'd'),
        endDate: timeToDate(nowTimestamp, 'd'),
      };

      let p1 = new Promise((resolve, reject) => {
        axios.post('/pay-app-admin/p/cs/user/isOpen').then((res) => {
          if (res.data.code === 0) {
            if (res.data.data && res.data.data.id) {
              let payInfo = {
                title: '支付数据',
                type: 'pay',
                show: true,
                leftText: '交易额（7日）',
                // leftNum: this.userInfo.payData.turnover,
                rightText: '交易量（7日）',
                // rightNum: this.userInfo.payData.dealVolume,
              };
              this.dataList.push(payInfo);
              axios.post('/pay-app-admin/p/cs/user/info').then((resCode) => {
                if (resCode.data.code === 0) {
                  let url;
                  if (resCode.data.data.is_agent === 'Y') {
                    // 支付服务商接口看板
                    url = '/pay-app-admin/p/cs/serviceProviderBoard';
                  } else {
                    // 支付接口看板
                    url = '/pay-app-admin/p/cs/getQueryData';
                  }
                  axios.post(url, params).then((resData) => {
                    if (resData.data.code === 0) {
                      if (resData.data.data) {
                        let index = this.dataList.findIndex(item => item.type === 'pay');
                        this.$set(this.dataList[index], 'leftNum', resData.data.data.getQueryData[0].total);
                        this.$set(this.dataList[index], 'rightNum', resData.data.data.getQueryData[1].total);
                      }
                    }
                  });
                }
              });
            } else {
              resolve();
            }
          }
        }).catch(() => {
          reject();
        });
      });
      let p2 = new Promise((resolve, reject) => {
        axios.post('/sms-app-admin/p/cs/user/isOpen').then((res) => {
          if (res.data.code === 0) {
            if (res.data.data && res.data.data.id) {
              let smsInfo = {
                title: '短信数据',
                type: 'sms',
                show: true,
                leftText: '发送量（7日）',
                // leftNum: this.userInfo.smsData.sendVolume,
                rightText: '剩余量（预估）',
                // rightNum: this.userInfo.smsData.surplusVolume,
              };
              this.dataList.push(smsInfo);
              axios.post('/sms-app-admin/p/cs/homepageBoardCusData', params).then((resData) => {
                if (resData.data.code === 0) {
                  if (resData.data.data) {
                    let index = this.dataList.findIndex(item => item.type === 'sms');
                    this.$set(this.dataList[index], 'leftNum', resData.data.data.smsSendCount);
                    this.$set(this.dataList[index], 'rightNum', resData.data.data.smsSurplus);
                  }
                }
                // this.dataList.push(smsInfo);
              });
            } else {
              resolve();
            }
          }
        }).catch(() => {
          reject();
        });
      });
      let p3 = new Promise((resolve, reject) => {
        axios.post('/invoice-app-admin/p/cs/user/isOpen').then((res) => {
          if (res.data.code === 0) {
            if (res.data.data && res.data.data.id) {
              let invInfo = {
                title: '发票数据',
                type: 'inv',
                show: false,
              };
              this.dataList.push(invInfo);
            } else {
              resolve();
            }
          }
        }).catch(() => {
          reject();
        });
      });
      Promise.all([p1, p2, p3]).then(() => {
        let noOpen = document.getElementById('noOpenAnimation');
        noOpen.style.display = 'block';
      }).catch(() => {});
    },
    mounted() {
      this.userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
      window.addEventListener('userReady', (res) => {
        this.userInfo = res.detail.userInfo;
      });
    }
  };
</script>
<style scoped lang="less">
    .payConsole{
        width: 100%;
        height: 100%;
        padding: 20px 0;
        min-width: 810px;
        display: flex;
        background: #F1F1F1;
        align-items: flex-start;
        .content-left {
            flex: 70% 1 1;
        }
        .content-right {
            /*flex: 1;*/
            flex: 30% 0 0;
        }
    }
    .content-left{

    }
    .recentVisit{
        min-width: 450px;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 6px 5px 16px 0 rgba(150, 195, 198, 0.13);
        border-radius: 3px;
    }
    .title{
        /*overflow: hidden;*/
        display: flex;
    }
    .tab{
        width: 4px;
        height: 15px;
        background: #FD8368;
        margin:35px 0 0 20px;
    }
    .visitText{
        margin:36px 0 0 6px;
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        line-height: 15px;
    }
    .platform{
        background: #F6F8FB;
    }
    .platformText{
        padding: 6px 15px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        text-align: center;
        position: relative;
        cursor: pointer;
    }
    .platformText:hover {
        background: #FD8368;
        color: #fff;
    }
    .data{
        margin-top:25px;
        /*width: 909px;*/
        height: 180px;
        background: #FFFFFF;
        box-shadow: 6px 5px 16px 0 rgba(150, 195, 198, 0.13);
        border-radius: 3px;
    }
    .dateTitle{
        font-size: 14px;
        font-weight: 300;
        color: #333333;
        line-height: 20px;
    }
    .left-block{
        margin-left: 30px;
    }
    .right-block{
        margin-left: 130px;
    }
    .leftNum{
        font-size: 30px;
        font-weight: bold;
        color: #FD8368;
        line-height: 35px;
        margin-top: 15px;
    }
    .rightNum{
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        line-height: 35px;
        margin-top: 15px;
    }
    .content-right{
        padding: 35px 35px 50px 35px;
        margin-left: 25px;
        min-width: 295px;
        /*height: 336px;*/
        background: #FFFFFF;
        box-shadow: 6px 5px 16px 0 rgba(150, 195, 198, 0.13);
        border-radius: 3px;
    }
    .userImg{
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: rgba(253, 100, 66, 0.8);
        color: #fff;
        font-size: 32px;
    }
    .userName{
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        margin-left: 16px;
    }
    .userId{
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
    .userIdNum{
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-left: 28px;
    }
</style>
